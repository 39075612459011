
import './css/main.css';

const Main = () => {
    return (
        <div className="main">
            <div className="section application">
                <h1 className="hero-title">EthereumPoW</h1>
                <h2 className="hero-sub-title">Name Service</h2>
                <p className="bullet-points">
                    <span className="point-item"><span className="check-mark"></span>On-Chain</span>
                    <span className="point-item"><span className="check-mark"></span>Decentralized</span>
                    <span className="point-item"><span className="check-mark"></span>Open Source</span>
                    <span className="point-item"><span className="check-mark"></span>Self-Sovereign</span>
                </p>
                <div className="btn btn-secondary whitelist">
                    <a style={{ textDecoration: 'none', color: 'white' }}href="https://app.wens.domains">Go to app</a>
                </div>
            </div>
        </div>
    )
};

export default Main;