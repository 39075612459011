import { Link } from 'react-router-dom';

import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useDisconnect, useAccount } from 'wagmi';

import './css/header.css';

const Header = () => {
    const { disconnect } = useDisconnect();
    const { openConnectModal } = useConnectModal();
    const { address, isConnected } = useAccount();

    const renderConnectButton = () => (
        <button
            className='btn btn-secondary'
            onClick={isConnected ? disconnect : openConnectModal}
            type="button"
        >
            {isConnected ? `${address.slice(0, 4)}...${address.slice(-4)}` : 'Connect Wallet'}
        </button>
    );

    return (
        <div className="header">
            <Link to="/" >
                <div className="header-title">
                    <div className="logo" />
                    <div className="title">WENS</div>
                </div>
            </Link>
            <div className="header-buttons">
                {renderConnectButton()}
            </div>
        </div>
    )
};

export default Header;
