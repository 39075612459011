import "@rainbow-me/rainbowkit/styles.css";
import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { publicProvider } from "wagmi/providers/public";

import { Routes, Route, BrowserRouter } from "react-router-dom";

import { chain } from "./constants/chains.js";
import Footer from "./components/Footer.js";
import Header from "./components/Header.js";
import Main from "./components/Main.js";

import './App.css';

const { chains, provider } = configureChains(
	Object.values(chain),
	[publicProvider()]
);
const { connectors } = getDefaultWallets({
	appName: "WENS",
	chains
});

const wagmiClient = createClient({
	autoConnect: true,
	connectors,
	provider
});

function App() {

	return (
		<BrowserRouter>
			<WagmiConfig client={wagmiClient}>
				<RainbowKitProvider chains={chains}>
					<div className="app">
						<Header />
						<div className="content">
							<Routes>
								<Route path="*" element={<Main />} />
							</Routes>
						</div>
						<Footer />
					</div>
				</RainbowKitProvider>
			</WagmiConfig>
		</BrowserRouter>
	);
}

export default App;